<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="22">
                    </span>
                </a>

                <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="" height="60">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="60">
                        <!--            <img src="assets/images/logo-light.png" alt="" height="19">-->
                    </span>
                </a>
            </div>

        </div>

        <div class="d-flex">

                <button (click)="logout()" type="button" class="btn btn-homey-cancel bx2" id="page-header-user-dropdown">
                    <strong>Cerrar sesión</strong>
                </button>

        </div>
    </div>
</header>