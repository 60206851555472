import { MenuItem } from './menu.model';

export const MENU_SELLER: MenuItem[] = [
    {
        id: 1,
        label: 'Seguimiento',
        icon: 'Mi Negocio',
        link: 'administration/points',
    },
    // {
    //     id: 2,
    //     label: 'Reportes',
    //     icon: 'Analitycs',
    //     permission: ['ADMINISTRATOR'],
    //     subItems: [
    //         {
    //             id: 1,
    //             label: 'Sesiones',
    //             link: 'sessions',
    //             parentId: 2
    //         },
    //         {
    //             id: 2,
    //             label: 'Ordenes técnicas',
    //             link: 'otreport',
    //             parentId: 2
    //         }
    //     ]
    // },
    {
        id: 3,
        label: 'Configuración',
        icon: 'Configuración',
        permission: ['ADMINISTRATOR'],
        subItems: [
            {
                id: 1,
                label: 'Usuarios',
                icon: 'Productos',
                link: 'administration/users',
                parentId: 3
            },
            {
                id: 2,
                label: 'Clientes',
                link: 'administration/clients',
                parentId: 3
            },
            {
                id: 3,
                label: 'Dispositivos',
                link: 'administration/devices',
                parentId: 3
            }
        ]
    }
];

