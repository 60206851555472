<div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-6">
                <div class="card overflow-hidden">
                    <div class="bg-soft">
                        <div class="row">
                            <div class="col-2 align-self-end">
                            </div>
                            <div class="col-8 align-self-end">
                                <img src="assets/images/logo.png" alt="" class="img-fluid">
                            </div>
                            <div class="col-2 align-self-end">
                            </div>
                        </div>
                        <!-- <p style="font-size: 24px; color: rgb(105, 105, 105); text-align: center; margin-top: 25px; margin-bottom: 25px">Te damos la bienvenida</p>
                        <p style="font-size: 24px; color: rgb(105, 105, 105); text-align: center;  margin-top: 25px; margin-bottom: 25px">Ingresa con tu email de empresa</p> -->
                    </div>
                    <div class="card-body">
                        <div class="p-2">
                            <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                                <div class="mb-3">
                                    <div class="input-group auth-pass-inputgroup justify-content-center align-items-center" >
                                        <i class="mdi mdi-email-outline" style="font-size: 24px;"></i>
                                        <input type="email" formControlName="user_email" class="form-control" id="email" placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && f.user_email.errors }" />
                                        <div *ngIf="submitted && f.user_email.errors" class="invalid-feedback">
                                            <div *ngIf="f.user_email.errors.required">Email requerido</div>
                                            <div *ngIf="f.user_email.errors.email">Email no válido</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="input-group auth-pass-inputgroup justify-content-center align-items-center">
                                        <i class="mdi mdi-lock-outline" style="font-size: 24px;"></i>
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="user_password" placeholder="Enter password"
                                               [ngClass]="{ 'is-invalid': submitted && f.user_password.errors }" aria-label="Password"
                                               aria-describedby="password-addon">
                                           
                                        <div *ngIf="submitted && f.user_password.errors" class="invalid-feedback">
                                          <span *ngIf="f.user_password.errors.required">Password is required</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 d-grid">
                                    <button style="background: #007E3D" class="btn btn-primary" type="submit">INICIAR SESIÓN</button>
                                </div>
                                <div class="mt-4 text-center">
                                    <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Recuperar contraseña</a>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- end container -->
</div>
<!-- end page -->